*{
  margin: 0;
  font-weight: lighter;
  box-sizing: border-box;
  justify-content: space-between;

}body {
  font-family: sans-serif;
  margin: 3rem;
  display: flex;
}.test{
  display: flex;
}.card{
  padding: 20px ;
  border-radius: 12px;
  border: 1px #f5f5f5 solid;
  width: 18rem;
  margin: 50px;
}.btn {
  padding: 0.5rem 1.5rem;
  width: 150px;
  border-radius: 20px;
  color: #7096ff;
  border: 1px solid #7096ff;
  background-color: white; 
}.actions{
  padding-bottom: 12px;
}.text{
  color: lightgrey;
  padding-bottom: 50px; 
}.photo{
  width: 25px;
  border-radius: 60%;
  margin-left: 23%;
  margin-top: 3px;
}.box{
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
}.box-2{
  flex: 1;
  border-radius: 50%;
}.box-1{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 25px;
  color: #fff;
  background-color: #01c853;
  border-radius: 5px;
} .box-3{
  flex: 2;
  justify-content: center;
}.text-1{
  width: fit-content;
}.box-1-2{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  color: #fff;
  background-color: #2962ff;
  border-radius: 5px;
}.box-1-2-3{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  color: #fff;
  background-color: #c35bff;
  border-radius: 5px;
}.description{
  color: lightgrey;
  padding-bottom: 25px; 
}